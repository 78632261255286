import { createNotifyingErrorEffect } from 'utils/store';
import { createEffect, createStore } from 'effector';
import { API } from 'api';

import type { CreateDocumentParams } from './types';

// * effects
export const createDocumentFx = createEffect({
    handler: async (data?: CreateDocumentParams) => {
        if (data) {
            const { description, massage, fileId, draft, name, pdf } = data;
            const payload: KIARA_API.DocumentRequest = {
                description,
                pdf: fileId,
                massage,
                draft,
                name
            };

            await API.documents.createDocument(payload);
            pdf.save(name);
        }
    }
});

export const getDocumentsFx = createNotifyingErrorEffect({ handler: async () => await API.documents.getDocuments() });

export const getSingleDocumentFx = createNotifyingErrorEffect({
    handler: async (id: string) => await API.documents.getSingleDocument(id)
});

export const deleteDocumentFx = createNotifyingErrorEffect({
    handler: async (id: string) => await API.documents.deleteDocument(id)
});

// * stores
export const $documents = createStore<KIARA_API.DocumentResponse[]>([]).on(
    getDocumentsFx.doneData,
    (_, payload) => payload
);
export const $singleDocument = createStore<KIARA_API.DocumentResponse | null>(null).on(
    getSingleDocumentFx.doneData,
    (_, payload) => payload
);
