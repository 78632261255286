import { initializeGenericStore, initializeStoreModal } from 'utils/store';
import { CopyTemplateEnum } from 'constants/enums';
import { $alertStore } from 'stores/alerts/model';
import { combine } from 'effector';

import type {
    CompanyTemplateNamingModalStore,
    CompanyUserChangeModalStore,
    DeleteCompanyUserModalStore,
    VariationsFieldModalStore,
    VariationsBlockModalStore,
    CopyTemplateModalStore,
    LeaveBuilderModalStore,
    ColorPalletModalStore,
    DescriptionModalStore,
    AreYouSureModalStore,
    ComingSoonModalStore,
    TemplateModalStore,
    AddUserModalStore,
    FieldModalStore,
    SubjectType
} from './types';

// * simple modals stores
export const notAuthorizedModalStore = initializeStoreModal();
export const companyUserChangeModal = initializeStoreModal();
export const deleteCompanyUserModal = initializeStoreModal();
export const cookiesModalStore = initializeStoreModal();
export const leaveDraftModalStore = initializeStoreModal();
export const createReminderModalStore = initializeStoreModal();
export const fontsModalStore = initializeStoreModal();
export const loginModalStore = initializeStoreModal();
export const previewModeModalStore = initializeStoreModal();

// * generic modals stores
export const areYouSureModalStore = initializeGenericStore<AreYouSureModalStore>({
    handleSubmit: () => {},
    title: '',
    text: ''
});
export const updateSubscriptionModalStore = initializeGenericStore<{ subtitleText: string }>({ subtitleText: '' });
export const companyTemplateNamingModalStore = initializeGenericStore<CompanyTemplateNamingModalStore>({});
export const companyUserChangeModalStore = initializeGenericStore<CompanyUserChangeModalStore>({
    user: undefined
});
export const deleteCompanyUserModalStore = initializeGenericStore<DeleteCompanyUserModalStore>({
    handleDeleteCompanyUser: _id => {},
    id: ''
});
export const descriptionModalStore = initializeGenericStore<DescriptionModalStore>({
    backgroundWidth: '',
    description: ''
});
export interface RemoveTrendingModal {
    subject?: SubjectType;
    subjectName?: string;
    id?: string;
}
export const leaveBuilderModalStore = initializeGenericStore<LeaveBuilderModalStore>({
    isUpdatingTemplate: false,
    documentName: '',
    config: {}
});
export const comingSoonModalStore = initializeGenericStore<ComingSoonModalStore>({ contractName: '' });
export const addUserModalStore = initializeGenericStore<AddUserModalStore>({ title: '' });
export const templateModalStore = initializeGenericStore<TemplateModalStore>({
    documentName: '',
    config: {}
});
export const variationsFieldModalStore = initializeGenericStore<VariationsFieldModalStore>({});
export const variationsBlockModalStore = initializeGenericStore<VariationsBlockModalStore>({});
export const fieldModalStore = initializeGenericStore<FieldModalStore>({ contentId: '' });
export const colorPalletModalStore = initializeGenericStore<ColorPalletModalStore>({
    palletToUpdate: {
        second: '',
        fourth: '',
        first: '',
        third: ''
    }
});
export const copyTemplateModalStore = initializeGenericStore<CopyTemplateModalStore>({
    from: CopyTemplateEnum.Kiara,
    templateId: ''
});

// * combined modals store
export const combinedModalsStore = combine(
    {
        companyTemplateNamingModalStore: companyTemplateNamingModalStore.modal,
        updateSubscriptionModalStore: updateSubscriptionModalStore.modal,
        variationsBlockModalStore: variationsBlockModalStore.modal,
        variationsFieldModalStore: variationsFieldModalStore.modal,
        createReminderModalStore: createReminderModalStore.modal,
        notAuthorizedModalStore: notAuthorizedModalStore.modal,
        copyTemplateModalStore: copyTemplateModalStore.modal,
        leaveBuilderModalStore: leaveBuilderModalStore.modal,
        colorPalletModalStore: colorPalletModalStore.modal,
        descriptionModalStore: descriptionModalStore.modal,
        previewModeModalStore: previewModeModalStore.modal,
        areYouSureModalStore: areYouSureModalStore.modal,
        comingSoonModalStore: comingSoonModalStore.modal,
        leaveDraftModalStore: leaveDraftModalStore.modal,
        templateModalStore: templateModalStore.modal,
        addUserModalStore: addUserModalStore.modal,
        cookiesModalStore: cookiesModalStore.modal,
        fieldModalStore: fieldModalStore.modal,
        fontsModalStore: fontsModalStore.modal,
        alertsStores: $alertStore
    },
    ({
        companyTemplateNamingModalStore,
        updateSubscriptionModalStore,
        variationsBlockModalStore,
        variationsFieldModalStore,
        createReminderModalStore,
        notAuthorizedModalStore,
        copyTemplateModalStore,
        leaveBuilderModalStore,
        colorPalletModalStore,
        descriptionModalStore,
        previewModeModalStore,
        areYouSureModalStore,
        comingSoonModalStore,
        leaveDraftModalStore,
        templateModalStore,
        addUserModalStore,
        cookiesModalStore,
        fieldModalStore,
        fontsModalStore,
        alertsStores
    }) => ({
        isCompanyTemplateNamingModalVisible: companyTemplateNamingModalStore[0],
        isUpdateSubscriptionModalVisible: updateSubscriptionModalStore[0],
        isCreateReminderModalVisible: createReminderModalStore.visible,
        isNotAuthorizedModalVisible: notAuthorizedModalStore.visible,
        isVariationsFieldModalVisible: variationsFieldModalStore[0],
        isVariationsBlockModalVisible: variationsBlockModalStore[0],
        isPreviewModeModalVisible: previewModeModalStore.visible,
        isLeaveDraftModalVisible: leaveDraftModalStore.visible,
        isLeaveBuilderModalVisible: leaveBuilderModalStore[0],
        isCopyTemplateModalVisible: copyTemplateModalStore[0],
        isColorPalletModalVisible: colorPalletModalStore[0],
        isDescriptionModalVisible: descriptionModalStore[0],
        isAlertsModalVisible: Boolean(alertsStores.active),
        isAreYouSureModalVisible: areYouSureModalStore[0],
        isComingSoonModalVisible: comingSoonModalStore[0],
        isCookiesModalVisible: cookiesModalStore.visible,
        isTemplateModalVisible: templateModalStore[0],
        isFontsModalVisible: fontsModalStore.visible,
        isAddUserModalVisible: addUserModalStore[0],
        isFieldModalVisible: fieldModalStore[0]
    })
);
