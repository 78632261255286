import { type ButtonHTMLAttributes } from 'react';
import { type FC } from 'types/react';

import { StyledButton } from './styles';

export type ButtonVariant = 'secondary' | 'primary';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant: ButtonVariant;
}

export const Button: FC<ButtonProps> = ({ children, disabled, variant, ...rest }) => (
    <StyledButton disabled={disabled} variant={variant} {...rest}>
        {children}
    </StyledButton>
);
