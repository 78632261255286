import { createNotifyingSuccessEffect, createNotifyingErrorEffect } from 'utils/store';
import { createEffect, createEvent, createStore, restore } from 'effector';
import { USER_LOCAL_STORAGE_NAME } from 'constants/local-storage';
import { persist } from 'effector-storage/local';
import { API } from 'api';

import { removeSecureDataFromUser } from './helpers/remove-secure-data-from-user';

// * events
export const setUserEvent = createEvent<KIARA_API.AuthResponseDto>();

// * effects
export const loadTokenFx = createNotifyingErrorEffect({
    handler: async (data: API.LoginDto) => await API.user.authenticateUser(data)
});

export const verifyAccountFx = createNotifyingErrorEffect({
    handler: async (token: string) => await API.user.verifyAccount(token)
});

export const createUserFx = createNotifyingErrorEffect({
    handler: async (data: API.SignUpDto) => await API.user.createUser(data)
});

export const checkEmailForExistenceFx = createNotifyingErrorEffect({
    handler: async (data: { email: string }) => await API.user.checkEmailForExistence(data)
});

export const recoverPasswordFx = createNotifyingErrorEffect({
    handler: async (data: API.ForgotPasswordDto) => await API.user.recoverPassword(data)
});

export const updateUserFx = createNotifyingErrorEffect({
    handler: async (data: API.UpdateUserDto) => await API.user.updateUser(data)
});

export const updateUserPasswordFx = createNotifyingSuccessEffect({
    handler: async (data: API.UpdatePasswordDto) => await API.user.updateUserPassword(data)
});

export const getMeFx = createNotifyingErrorEffect({
    handler: async () => await API.user.getMe()
});

export const changeUserRoleFx = createNotifyingErrorEffect({
    handler: async (data: { userId: string; role: string }) => await API.user.changeUserRole(data)
});

export const changePasswordFx = createNotifyingErrorEffect({
    handler: async (data: { password: string; token: string }) => await API.user.changePassword(data)
});

export const refreshTokenFx = createNotifyingErrorEffect({
    handler: async () => await API.user.refreshToken()
});

export const logoutFx = createEffect({
    handler: async () => await API.user.logout()
});

export const getUserLimitationsInfoFx = createNotifyingErrorEffect({
    handler: async () => await API.user.getUserLimitationInfo()
});

// * stores
export const $user = createStore<KIARA_API.User | null>(null)
    .on(loadTokenFx.doneData, (_, payload) => removeSecureDataFromUser(payload))
    .on(createUserFx.doneData, (_, payload) => payload)
    .on(updateUserFx.doneData, (_, payload) => payload)
    .on(refreshTokenFx.doneData, (_, payload) => removeSecureDataFromUser(payload))
    .on(getMeFx.doneData, (_, payload) => payload)
    .on(setUserEvent, (_, payload) => removeSecureDataFromUser(payload));

persist({ key: USER_LOCAL_STORAGE_NAME, store: $user });

export const $doesEmailExist = createStore(false)
    .on(checkEmailForExistenceFx.fail, (_, _data) => false)
    .on(checkEmailForExistenceFx.done, (_, _data) => true);

export const $userLimitationInfo = restore<KIARA_API.UserLimitationInfo>(getUserLimitationsInfoFx.doneData, null);
