import type { FC } from 'types/react';

import { resetOwnTemplateEvent, updateOwnTemplateFx, $ownTemplate } from 'stores/ownTemplates/model';
import { leaveBuilderModalStore, templateModalStore } from 'stores/modals/model';
import { questionnaireEvents } from 'stores/builder/questionnaire';
import { resetBlocksEvent } from 'stores/builder/block/model';
import { Button } from 'ui-kit/components/buttons/Button';
import { TEMPLATES_LINK } from 'constants/routes';
import { Modal } from 'ui-kit/components/Modal';
import { useNavigate } from 'react-router-dom';
import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import { AreYouSureIcon } from '../AreYouSureModal/static_assets';
import { ButtonsContainer, BodyContainer, Text } from './styles';

export const LeaveBuilderModal: FC = () => {
    const { closeModal, modal } = leaveBuilderModalStore;
    const [_, { isUpdatingTemplate, documentName, config }] = useUnit(modal);
    const ownTemplate = useUnit($ownTemplate);
    const navigate = useNavigate();

    const goBackToDashboardTemplates = () => {
        navigate(TEMPLATES_LINK);
        closeModal();

        resetBlocksEvent();
        questionnaireEvents.resetQuestionnaireEvent();
        resetOwnTemplateEvent();
    };

    const handleSaveTemplate = useCallback(async () => {
        if (ownTemplate) {
            const data = {
                templateName: ownTemplate?.templateName,
                templateCategory: 'Hiring',
                templateType: 'Contract',
                templateConfig: config,
                documentName
            };

            await updateOwnTemplateFx({ id: ownTemplate?.id, data });
            closeModal();
        }
    }, [closeModal, config, documentName, ownTemplate]);

    const handleCreateTemplate = () => {
        closeModal();

        templateModalStore.openModal({ documentName, config });
    };

    return (
        <Modal title={'Unsaved changes'} onClose={closeModal} visible>
            <BodyContainer>
                <AreYouSureIcon />
                <Text>
                    You are about to leave this template without saving. All changes will be lost. Do you really want to
                    discard changes?
                </Text>
            </BodyContainer>
            <ButtonsContainer>
                <Button onClick={goBackToDashboardTemplates} variant="secondary">
                    Discard changes
                </Button>
                <Button onClick={isUpdatingTemplate ? handleSaveTemplate : handleCreateTemplate} variant="primary">
                    Save template
                </Button>
            </ButtonsContainer>
        </Modal>
    );
};
