import { createEffect, createEvent, createStore, restore } from 'effector';
import { createNotifyingErrorEffect } from 'utils/store';
import { API } from 'api';

// * events
export const resetDraftEvent = createEvent();
export const changeDraftNameEvent = createEvent<string>();

// * effects
export const createDraftFx = createEffect({
    handler: async (data: KIARA_API.UserDraftsRequest) => await API.draft.createDraft(data)
});

export const getDraftsFx = createNotifyingErrorEffect({ handler: async () => API.draft.getDrafts() });

export const getDraftFx = createNotifyingErrorEffect({ handler: async (id: string) => API.draft.getDraft(id) });

export const deleteDraftFx = createNotifyingErrorEffect({
    handler: async (id: string) => await API.draft.deleteDraft(id)
});

export const updateDraftFx = createEffect({
    handler: async ({
        templateCategory,
        templateConfig,
        documentName,
        templateName,
        templateType,
        id
    }: KIARA_API.UserDraftsRequest) =>
        await API.draft.updateDraft(
            { templateCategory, templateConfig, documentName, templateName, templateType },
            id || ''
        )
});

// * stores
export const $drafts = createStore<KIARA_API.UserDraftsResponse[]>([]);
export const $draft = createStore<KIARA_API.UserDraftsResponse | null>(null);
export const $draftSignName = restore<string>(changeDraftNameEvent, '');
export const $draftSignEmails = createStore<string[]>([]);
export const $draftSignMessage = createStore<string>('');

$drafts.on(getDraftsFx.doneData, (_, payload) => payload);
$draft.on(getDraftFx.doneData, (_, payload) => payload);
$draft.reset(resetDraftEvent);
